import { makeStyles } from '@material-ui/core/styles';

export const dotItemStyles = makeStyles(() => ({
  button: {
    color: '#aa0606',
  },
  check: {
    color: '#137536',
  },
}));
