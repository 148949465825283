import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { DotItem } from '../Item/DotItem';
import { DotListItem } from '../Item/DotListItem';

import { pricesStyles } from './styles';

const PricesPlan = () => {
  const { t } = useTranslation();
  const classes = pricesStyles();

  return (
    <>
      <Box mt={0}>
        <Box mb={3}>
          <Typography className={classes.contentHeadCenter}>{t('trans.pricesPlanHead')}</Typography>
          <Typography className={classes.contentHeadCenter}>{t('trans.pricesPlanHeadTwo')}</Typography>

          <Typography className={classes.contentHeadCategoryCenter}>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_1')} />
            </Box>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_2')} />
            </Box>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_3')} />
            </Box>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_4')} />
            </Box>
          </Typography>
        </Box>
        <Box className={classes.paperBox}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} xs={12}>
              <Box>
                <Paper className={classes.paper}>
                  <Box className={classes.titelborder}>
                    <Typography className={classes.headingbold}>{t('trans.pricesPlanForFree')}</Typography>
                    <Typography className={classes.headingContent}>{t('trans.pricesPlanForFreeContent')}</Typography>
                  </Box>

                  <Box ml={1} mt={3}>
                    <Typography className={classes.headingtree}>{t('trans.pricesPlanInklusiveForFree')}</Typography>
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_1')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_2')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_3')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_4')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_5')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_6')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_7')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanForFreeContent_9')} />
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Box>
                <Paper className={classes.paper}>
                  <Box className={classes.titelborder}>
                    <Typography className={classes.headingbold}>{t('trans.pricesPlanBusiness')}</Typography>
                    <Typography className={classes.headingContent}>{t('trans.pricesPlanBusinessContent')}</Typography>
                  </Box>

                  <Box ml={1} mt={3}>
                    <Typography className={classes.headingtree}>{t('trans.pricesPlanInklusiveBusiness')}</Typography>
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_0')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_1')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_2')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_3')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_5')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_6')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_7')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_7a')} />
                    {/* <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_8')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_8a')} /> */}
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_9')} />
                    <DotListItem textEintragDot={t('trans.pricesPlanBusinessContent_10')} />
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PricesPlan;
