import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
// import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonCheckedIcon from '@material-ui/icons/Check';
import { dotItemStyles } from '../styles';

export const DotListItem = (props) => {
  const classes = dotItemStyles();
  const { textEintragDot } = props;
  return (
    <Typography>
      <RadioButtonCheckedIcon className={classes.check} viewBox="0 -26 50 50" />
      {textEintragDot}
    </Typography>
  );
};

DotListItem.propTypes = {
  textEintragDot: PropTypes.string.isRequired,
};
